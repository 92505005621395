import { cn } from "@/utils/helpers"
import * as React from "react"
import { Button, ButtonProps, buttonVariants } from "./Button"
import {
  faChevronLeft,
  faChevronRight,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { Icon } from "@/features/icons/components/IconLoader"
import Link from "next/link"
import { Label } from "./Label"

const Pagination = ({
  className,
  ...props
}: React.ComponentProps<"nav"> & { isSimple?: boolean }) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn(
      "mx-auto flex w-full flex-wrap justify-center gap-1",
      props.isSimple && "flex-row-reverse flex-nowrap justify-start",
      className,
    )}
    {...props}
  />
)
Pagination.displayName = "Pagination"

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn("flex flex-row items-center gap-1", className)}
    {...props}
  />
))
PaginationContent.displayName = "PaginationContent"

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("", className)} {...props} />
))
PaginationItem.displayName = "PaginationItem"

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, "size"> &
  ButtonProps & { isSimple?: boolean }

const PaginationLink = ({
  className,
  isActive,
  size = "icon",
  isSimple,
  ...props
}: PaginationLinkProps) => (
  <Button
    variant={isActive ? "default" : isSimple ? "outline" : "text"}
    size={"icon"}
    className={cn("", className)}
    {...props}
    type="button"
  />
)
PaginationLink.displayName = "PaginationLink"

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="default"
    className={cn("gap-1", className)}
    {...props}
  >
    <Icon icon={faChevronLeft} />
  </PaginationLink>
)
PaginationPrevious.displayName = "PaginationPrevious"

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Go to next page"
    size="default"
    className={cn("gap-1", className)}
    {...props}
  >
    <Icon icon={faChevronRight} />
  </PaginationLink>
)
PaginationNext.displayName = "PaginationNext"

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    ...
    {/* <DotsHorizontalIcon className="h-4 w-4" /> */}
    <span className="sr-only">More pages</span>
  </span>
)
PaginationEllipsis.displayName = "PaginationEllipsis"

const PaginationBreak = () => (
  <div className="h-0" style={{ flexBasis: "100%" }} />
)
PaginationBreak.displayName = "PaginationBreak"

const PaginationSubtext = ({
  children,
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn("mx-2 flex w-fit items-center justify-center", className)}
    {...props}
  >
    <Label size="sm" className="text-on-surface-variant text-xs">
      {children}
    </Label>
  </span>
)
PaginationEllipsis.displayName = "PaginationSubtext"

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
  PaginationSubtext,
  PaginationBreak,
}
